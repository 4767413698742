import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IndivItem from "../components/shop/indivitem"

import { InstructionsProvider } from "../components/shop/context/instructionsContext"

export const query = graphql`
  query($slug: String!) {
    allDatoCmsProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          name
          price
          description
          instructions
          ingredientAndDosage
          slug
          photos {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`

const Product = ({ data }) => {
  const product = data.allDatoCmsProduct.edges[0].node

  return (
    <Layout>
      <SEO title={product.name} />
      <InstructionsProvider
        value={{
          productType: 'product',
          instructions: product.instructions,
          ingredients: product.ingredientAndDosage,
        }}
      >
        <IndivItem product={product} />
      </InstructionsProvider>
    </Layout>
  )
}

export default Product
